<div
  *ngIf="rcUserProfile != null && rcUserProfile.isLoggedIn"
  class="content-wrap"
>
  <!-- sidebar -->

  <aside class="filter-sidebar">
    <div class="view-mode-box">
      <p>View Mode</p>
      <div class="view-type-switch">
        <button
          (click)="changeViewType('NORMAL')"
          [class.active]="viewType === 'NORMAL'"
        >
          Normal
        </button>
        <button
          (click)="changeViewType('BRIEF')"
          [class.active]="viewType === 'BRIEF'"
        >
          Brief
        </button>
        <button
          (click)="changeViewType('VALUE')"
          [class.active]="viewType === 'VALUE'"
        >
          Value
        </button>
      </div>
    </div>
    <div class="filter-container" *ngIf="viewType !== 'VALUE'">
      <form class="filter-form" [formGroup]="filterFormGroup">
        <div>
          <div class="field-container">
            <label for="category">Pick Category</label>
            <mat-form-field appearance="outline" class="cselect">
              <input
                matInput
                [matAutocomplete]="auto"
                aria-label="categoryCode"
                name="categoryCode"
                class="sselect"
                formControlName="category"
                [(ngModel)]="queryCategoryCode"
              />
            </mat-form-field>
            <mat-autocomplete class="filter-" #auto="matAutocomplete">
              <mat-option
                class="filter-autocomplete-cat"
                *ngFor="let option of categoriesFiltered"
                [value]="option"
              >
                {{ option }}
              </mat-option>
            </mat-autocomplete>
          </div>

          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let option of categoriesFiltered"
              [value]="option"
            >
              {{ option }}
            </mat-option>
          </mat-autocomplete>

          <div class="field-container">
            <label for="coindate">Coin Date</label>
            <mat-form-field appearance="outline">
              <input
                matInput
                formControlName="coinDate"
                name="coinDate"
                [(ngModel)]="queryCoinDate"
                id="coindate"
              />
            </mat-form-field>
          </div>
          <div class="field-container" style="padding-bottom: 1.25em">
            <label for="coinmint">Coin Mint</label>
            <mat-button-toggle-group
              class="filter-submits"
              name="coinMints"
              formControlName="coinMintArr"
              aria-label="Coin Mints"
              multiple
            >
              <!-- <div class="filter-submits" formGroupName="coinMintArr"> -->
              <mat-button-toggle
                *ngFor="let coinMint of coinMintList"
                class="btn-prime smaller"
                [class.active]="queryCoinMint === coinMint"
                [value]="coinMint"
              >
              {{coinMint}}
              </mat-button-toggle>
              <!-- </div> -->
            </mat-button-toggle-group>
            <div class="filter-submits"></div>
            <!--<input
              matInput
              name="CoinMint"
              [(ngModel)]="queryCoinMint"
              id="coinmint"
              [ngModelOptions]="{ standalone: true }"
            /> -->
          </div>
          <div class="field-container">
            <label for="coinFeaturesList">Set coin extra feature value</label>
            <mat-form-field appearance="outline">
              <mat-select
                matInput
                class="bur"
                name="coinFeaturesList"
                aria-label="coinFeature"
                formControlName="coinExtraFeature"
                [(ngModel)]="queryCoinFeature"
              >
                <mat-option></mat-option>
                <mat-option
                  *ngFor="let option of coinFeatures"
                  [value]="option"
                >
                  {{ option["id"] }}@{{ option["value"] }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- <div class="field-container">
            <label for="sdate-start">Scan Date Start</label>

            <mat-form-field appearance="outline">
              <input
                matInput
                type="datetime-local"
                [(ngModel)]="listStartDate"
                [ngModelOptions]="{ standalone: true }"
              />
            </mat-form-field>
          </div>
          <div class="field-container">
            <label for="sdate-end">Scan Date End</label>

            <mat-form-field appearance="outline">
              <input
                matInput
                type="datetime-local"
                [(ngModel)]="listEndDate"
                [ngModelOptions]="{ standalone: true }"
              />
            </mat-form-field>
          </div> -->

          <div class="field-container date-range">
            <label>Enter a date range</label>
            <mat-form-field>
              <input type="text"
                class="datepicker"
                matInput
                ngxDaterangepickerMd
                formControlName="dateRange"
                [locale]="{applyLabel: 'ok', format: 'YYYY-MM-DDTHH:mm:ss'}"
                [timePicker]="true"
                [timePicker24Hour]="true"
                startKey="start"
                autocomplete="off"
                endKey="end"
                (datesUpdated)="onDatesUpdated($event)"
                name="daterange"/>
            </mat-form-field>

            <!-- <mat-form-field>
              <mat-date-range-input [rangePicker]="picker">
                <input
                  formControlName="dateStart"
                  matStartDate
                  placeholder="Start date"
                  [(ngModel)]="listStartDate"
                />
                <input
                  formControlName="dateEnd"
                  matEndDate
                  placeholder="End date"
                  [(ngModel)]="listEndDate"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field> -->
          </div>

          <div class="field-container radio-buttons-group">
            <label>Sort Type</label>
            <mat-radio-group
              formControlName="sortType"
              [(ngModel)]="sortingType"
            >
              <mat-radio-button
                *ngFor="let st of sortTypes"
                [value]="st"
                >{{ st }}</mat-radio-button
              >
            </mat-radio-group>
          </div>
        </div>

        <div>
          <div class="filter-btns">
            <button class="prime" (click)="clear()">Clear</button>
            <button class="active" (click)="onReload()">
              <img src="/assets/img/reload.svg" alt="" />
              Reload
            </button>
          </div>
          <div class="filter-submits" *ngIf="rcUserProfile.isAdmin">
            <button class="btn-prime" (click)="getStatData()">Get Stats</button>
            <button class="btn-prime" (click)="openAllStats()">
              Open page scans
            </button>
          </div>
        </div>
      </form>
    </div>
  </aside>
  <!-- main content area -->
  <div class="main-content brief-view" *ngIf="viewType === 'BRIEF'">
    <!-- <table class="brief-view-coins">
      <thead>
        <tr>
          <th>#</th>
          <th>timestamp</th>
          <th>Id</th>
          <th>category</th>
          <th>date</th>
          <th>mint</th>
          <th>coin features</th>
          <th>value</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let coin of coinScans; index as i">
          <td>
            {{ coinsScansPage * coinsPerPage + i + 1 }}
          </td>
          <td>
            {{ coin["timestamp"] }}
          </td>
          <td>
            <div class="coins-status">
              <a
                [routerLink]="['/coinscanview', coin['customid']]"
                target="_blank"
                class="coin-details-link"
                >{{ coin["customid"] }}</a
              >
            </div>
          </td>
          <td>
            {{ coin["categoryCode"] }}
          </td>
          <td>
            {{ coin["date"] }}
          </td>
          <td>
            {{ coin["mintSymbol"] }}
          </td>
          <td>
            <div
              *ngFor="let cf of getPropertynames(coin)"
              class="coin-list-coin-prop"
            >
              <div>
                <span class="dot"></span>{{ cf }} -
                {{ coin["coinFeatures"][cf] }}
              </div>
            </div>
          </td>
          <td>
            <a class="sort_level">{{
              sorterDataArray.get(coin["customid"])["rulesValueIndicator"][
                "level"
              ]
            }}</a>
            
          </td>
        </tr>
      </tbody>
    </table> -->
    <table mat-table [dataSource]="filteredCoins | paginate: {  id: 'server-brief', itemsPerPage: coinsPerPage, currentPage: coinsScansPage, totalItems: coinScansCount }" class="mat-elevation-z8">
      <ng-container matColumnDef="#">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let coin; let i = index">
          {{ ((coinsScansPage - 1) * coinsPerPage) + i + 1 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef>Timestamp</th>
        <td mat-cell *matCellDef="let coin">
          {{ coin["timestamp"] }}
          <span class="weight-label" *ngIf="coin.weightDisplay">&nbsp;| <b>Weight:</b> {{coin.weightDisplay}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="Id">
        <th mat-header-cell *matHeaderCellDef>Id</th>
        <td mat-cell *matCellDef="let coin">
          <a
            [routerLink]="['/coinscanview', coin['customid']]"
            target="_blank"
            class="coin-details-link"
            >{{ coin["customid"] }}</a
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef>Category</th>
        <td mat-cell *matCellDef="let coin">{{ coin["categoryCode"] }}</td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let coin">{{ coin["date"] }}</td>
      </ng-container>

      <ng-container matColumnDef="mint">
        <th mat-header-cell *matHeaderCellDef>Mint</th>
        <td mat-cell *matCellDef="let coin">
            <span>
              {{ coin["mintSymbol"] }}
            </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="date mintcoin features">
        <th mat-header-cell *matHeaderCellDef>date mintcoin features</th>
        <td mat-cell *matCellDef="let coin">
          <div
            *ngFor="let cf of getPropertynames(coin)"
            class="coin-list-coin-prop"
          >
            <div class="feature-info">
              <img src="/assets/img/arrow.svg" alt="arrow" />
              {{ cf }} -
              {{ coin["coinFeatures"][cf] }}
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef>Value</th>
        <td mat-cell *matCellDef="let coin">
          <div
            *ngIf="sorterDataArray.has(coin['customid'])"
            class="{{
              sorterDataArray.get(coin['customid'])['rulesValueIndicator'][
                'level'
              ] | lowercase
            }} img-wrapper"
          >
            <img
              src="./assets/img/{{
                sorterDataArray.get(coin['customid'])['rulesValueIndicator'][
                  'level'
                ] | lowercase
              }}.svg"
              alt="coin"
            />
            {{
              sorterDataArray.get(coin["customid"])["rulesValueIndicator"][
                "level"
              ]
            }}
          </div>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="[
          '#',
          'timestamp',
          'Id',
          'category',
          'date',
          'mint',
          'date mintcoin features',
          'value'
        ]"
      ></tr>
      <tr
        mat-row
        *matRowDef="
          let row;
          columns: [
            '#',
            'timestamp',
            'Id',
            'category',
            'date',
            'mint',
            'date mintcoin features',
            'value'
          ]
        "
      ></tr>
    </table>

    <mat-progress-bar mode="indeterminate" *ngIf="isWait"></mat-progress-bar>
    <div class="pagination">
      <pagination-controls class="coinscans-pagination" (pageChange)="loadPage($event)" id="server-brief"></pagination-controls>
    </div>
  </div>

  <div
    class="main-content"
    *ngIf="viewType === 'NORMAL' || viewType === 'VALUE'"
  >
    <div class="robo-container">
      <div class="all-coins">
        <div class="coins-wrapper" *ngFor="let coin of filteredCoins | paginate: {  id: 'server', itemsPerPage: coinsPerPage, currentPage: coinsScansPage, totalItems: coinScansCount }">
          <div class="coin-wrapper coin-data">
            <div class="coin-data-title">
              <a
                [routerLink]="['/coinscanview', coin['customid']]"
                target="_blank"
              >
                <h3># ID:</h3>
                <h4>{{ coin["customid"] }}</h4>
              </a>

              <div class="coin-status">
                <img
                  *ngIf="coin['verified']; else removeCoinTmpl"
                  src="/assets/img/coin-verified.svg"
                  alt="Coin verified icon"
                />
                <ng-template #removeCoinTmpl>
                  <button
                    class="coin-delete-icon"
                    mat-icon-button
                    (click)="deleteCoin(coin['customid'])"
                    aria-label="Delete coin button"
                  >
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </ng-template>
              </div>
            </div>
            <div class="coin-data-content">
              <div>
                <b>Category: </b>&nbsp;<span>{{ coin["categoryCode"] }}</span>
              </div>
              <div>
                <b>Date: </b>&nbsp;<span
                  >{{ coin["date"] }} - {{ coin["mintSymbol"] }}</span>
                  <span class="weight-label" *ngIf="coin.weightDisplay">&nbsp;| <b>Weight:</b> {{coin.weightDisplay}}</span>
              </div>
              <div>
                <b>Coin Features: </b>

                <ul class="coin-features">
                  <li *ngFor="let cf of getPropertynames(coin)">
                    <span>{{ cf }} - {{ coin["coinFeatures"][cf] }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="coin-wrapper coin-timestamp-value">
            <div class="coin-data-title">
              <a>
                <h3>Timestamp | Value</h3>
              </a>
            </div>
            <div class="coin-data-content">
              <div>
                <b>Owner: </b>&nbsp;<span>{{ coin["ownername"] }}</span>
              </div>
              <div>
                <b>Scan Date: </b>&nbsp;<span>{{ coin["timestamp"] }}</span>
                
              </div>
              <div
                *ngIf="sorterDataArray.has(coin['customid'])"
                class="coin-desc"
              >
                <b>Value Indicator:</b>&nbsp;
                <div
                  *ngIf="sorterDataArray.has(coin['customid'])"
                  class="{{
                    sorterDataArray.get(coin['customid'])[
                      'rulesValueIndicator'
                    ]['level'] | lowercase
                  }} img-wrapper"
                >
                  <img
                    src="./assets/img/{{
                      sorterDataArray.get(coin['customid'])[
                        'rulesValueIndicator'
                      ]['level'] | lowercase
                    }}.svg"
                    alt="coin"
                  />
                  {{
                    sorterDataArray.get(coin["customid"])[
                      "rulesValueIndicator"
                    ]["level"] | titlecase
                  }}
                </div>
                &nbsp;<span>
                  <a class="sort_desc">
                    {{
                      sorterDataArray.get(coin["customid"])[
                        "rulesValueIndicator"
                      ]["description"]
                    }}</a
                  >
                </span>
              </div>

              <div>
                <b>Description: </b>&nbsp;<span>{{ coin["description"] }}</span>
              </div>
            </div>
          </div>
          <div class="coin-image">
            <div
                class="coin-item"
                [ngStyle]="{'gap' : imageArray.has(coin['customid'] + '-O') ? '0' : '45px'}"
              >
              <span class="coin-image-title">Obverse</span>
              <img
                *ngIf="imageArray.has(coin['customid'] + '-O'); else imageLoading;"
                width="160px"
                [src]="imageArray.get(coin['customid'] + '-O')"
                alt="Coin Observe"
              />
            </div>
            <div
              class="coin-item"
              [ngStyle]="{'gap' : imageArray.has(coin['customid'] + '-R') ? '0' : '45px'}"
            >
            <span class="coin-image-title">Reverse</span>
            <img
              *ngIf="imageArray.has(coin['customid'] + '-R'); else imageLoading;"
              width="160px"
              [src]="imageArray.get(coin['customid'] + '-R')"
              alt="Coin Reverse"
            />
          </div>
          <ng-template #imageLoading>
            <mat-spinner [diameter]="50"></mat-spinner>
          </ng-template>
          </div>
        </div>
      </div>

      <mat-progress-bar mode="indeterminate" *ngIf="isWait"></mat-progress-bar>
      <div class="pagination" *ngIf="filteredCoins.length">
        <pagination-controls class="coinscans-pagination" (pageChange)="loadPage($event)" id="server"></pagination-controls>
      </div>
    </div>
  </div>
</div>
