import { Component, OnInit } from '@angular/core';
import { CoinsearchService, RCUserProfile } from '../coinsearch.service';
import { Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { formatDate } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TimePeriod } from 'ngx-daterangepicker-material/daterangepicker.component';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { FilterPipe } from '../maintoolbar/pipes/filter.pipe';
import { round } from 'lodash';
dayjs.extend(utc)

@Component({
  selector: 'app-coinscans',
  templateUrl: './coinscans.component.html',
  styleUrls: ['./coinscans.component.css']
})

export class CoinscansComponent implements OnInit {
  public viewType           : 'NORMAL' | 'BRIEF' | 'VALUE' = 'NORMAL';
  public coinScans          : any;
  public coinScansCount     : number = 0;
  public coinsScansPage     : number = 1;
  public coinsPerPage       : number = 10;
  public coinsPerPageNormal : number = 10;
  public coinsPerPageBrief  : number = 50;
  public pages              : number[];
  public isWait             : boolean=false;
  public queryCategoryCode  : string='';
  public queryCoinDate      : string='';
  public queryCoinMint      : string='';
  public queryCoinFeature   : string='';
  public statData           : any=null;
  public categories         : any=null;
  public categoriesFiltered : any=null;
  public coinFeatures       : any=null;
  public listStartDate      : string='';
  public listEndDate        : string='';
  public sortingType        : string='SCANDATE';

  public rcUserProfile      : RCUserProfile=null;
  public isLogged           : boolean= false;
  public userName           : string='';
  public isAdmin            : boolean= false;

  public debugFSImage       :any = null;
  public imageArray         :Map<string, SafeUrl>=new Map<string, SafeUrl>();
  public sorterDataArray    :Map<string, any>=new Map<string, any>();

  public imageSizeGet       :number=450;
  public sortTypes          :string[] = ['VALUE', 'SCANDATE'];  
  
  public filterFormGroup    : FormGroup;
  public filteredCoins      : any[] = [];
  public filterPipe         : FilterPipe = new FilterPipe();
  public coinMintList       : string[] = ['D', 'P', 'N', 'S', 'W'];

  constructor(private _coinSearchService: CoinsearchService, private route: Router, private sanitizer: DomSanitizer, private fb: FormBuilder) {
    this._coinSearchService.getRCUserProfile().then(response => {
      this.rcUserProfile = response;
      
    })
  }

  ngOnInit() {
    this.filterFormGroup = this.fb.group({
      category: [''],
      coinDate: [''],
      coinMintArr: [[]],
      coinExtraFeature: [''],
      dateRange: [''],
      sortType: ['']
    })
    this.requestData();
  }

  openAllStats() {
    for (let i = 0; i < this.coinScans.length; i++) {
      let newRelativeUrl = this.route.createUrlTree(['/coinscanview', this.coinScans[i]['customid']]);
      let baseUrl = window.location.href.replace(this.route.url, '');

      window.open(baseUrl + newRelativeUrl, '_blank');
    }
  }

  datesUpdated(event) {
    //console.log("🚀 ~ file: coinscans.component.ts:89 ~ CoinscansComponent ~ datesUpdated ~ event:", event)
  }

  calculatePages(data) {
    this.pages = [];
    let numberOfTabs: number = 10
    let coinsPages: number = Math.floor(this.coinScansCount / this.coinsPerPage)
    let start: number = (this.coinsScansPage - 1) - numberOfTabs / 2;
    if (coinsPages - start < numberOfTabs) {
      start = coinsPages - numberOfTabs
    }

    if (start < 0) {
      start = 0;
    }

    for (let i = start; i < start + numberOfTabs; i++) {
      if (i <= coinsPages) {
        this.pages.push(i)
      }
    }
    if (start + numberOfTabs <= coinsPages) {
      this.pages.push(coinsPages);
    }
  }

  getPropertynames(coin) {
    if (coin != null && coin.hasOwnProperty('coinFeatures')) {
      return Object.getOwnPropertyNames(coin['coinFeatures'])
    }
    return [];
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {

      this.debugFSImage = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getCSSorterData(coins) {
    for (let i = 0; i < coins.length; i++) {
      let c = coins[i];
      this._coinSearchService.getSorterItenData(c['customid']).subscribe(
        result => {
          if ('rulesValueIndicator' in result) {
            this.sorterDataArray.set(c['customid'], result);
          }
        });
    }
  }


  getCSImages(coins) {
    for (let i = 0; i < coins.length; i++) {
      let c = coins[i];
      this._coinSearchService.getCoinScanImage(c['customid'], 'O', this.imageSizeGet).subscribe(
        result => {
          let objectURL = URL.createObjectURL(result);
          // '/assets/img/getcoinscansimage.jpeg'
          this.imageArray.set(c['customid'] + "-" + 'O', this.sanitizer.bypassSecurityTrustUrl(objectURL));
        });
      this._coinSearchService.getCoinScanImage(c['customid'], 'R', this.imageSizeGet).subscribe(
        result => {
          let objectURL = URL.createObjectURL(result);
          this.imageArray.set(c['customid'] + "-" + 'R', this.sanitizer.bypassSecurityTrustUrl(objectURL));
        });
    }
  }

  renderStatus(data) {
    this.isWait = false;
    this.coinScansCount = data['scanscount'];
    this.coinScans = data['coinscans'];
    this.calculatePages(data)

    this.getCSImages(this.coinScans)
    this.getCSSorterData(this.coinScans)
    for (let i = 0; i < this.coinScans.length; i++) {
      let coinDate = new Date(this.coinScans[i]['timestamp']);
      // Format timestamp without weight
      this.coinScans[i]['timestamp'] = formatDate(coinDate, 'yyyy-MM-dd HH:mm', 'en-US');
      // Add weight as a separate field if present
      if (this.coinScans[i]['weight']) {
        this.coinScans[i]['weightDisplay'] = `${round(this.coinScans[i]['weight'], 2)}g`;
      } else {
        this.coinScans[i]['weightDisplay'] = '';
      }
    }
    this.filteredCoins = this.coinScans;
  }

  getStatData() {
    //console.log('coin scans requested');
    this._coinSearchService.getCoinSansStats()
      .subscribe({
        next: (data) => this.statData = data,
        error: (error) => console.error('Error!', error)
      })
  }

  deleteScans(id) {
    this.isWait = true;

    this._coinSearchService.deleteScans(id)
    .subscribe({
      next: (data) => this.requestData(),
      error: (error) => console.error('Error!', error)
    })
  }

  renderCategories(cats) {
    this.categories = cats;
    this.categoriesFiltered = cats;
  }

  clear() {
    this.filterFormGroup.setValue({
      category: '',
      coinDate: '',
      coinMintArr: [],
      coinExtraFeature: '',
      dateRange: '',
      sortType: 'SCANDATE'
    })
    this.filteredCoins = this.filterPipe.transform(this.coinScans, this.filterFormGroup.value)
  }

  onDatesUpdated(value: TimePeriod) {
    if(!value.startDate && !value.endDate) return;
    const from = dayjs(new Date(
      value.startDate.get('y'),
      value.startDate.get('M'),
      value.startDate.get('D'),
      value.startDate.get('h'),
      value.startDate.get('m'),
      value.startDate.get('s'),
      value.startDate.get('ms'),
    ));
  
    const to = dayjs(new Date(
      value.endDate.get('y'),
      value.endDate.get('M'),
      value.endDate.get('D'),
      value.endDate.get('h'),
      value.endDate.get('m'),
      value.endDate.get('s'),
      value.endDate.get('ms'),
    ));

    this.filterFormGroup.get('dateRange').setValue({
      from: from,
      to: to
    })
  }

  onReload() {
    this.requestData();
    this.filteredCoins = this.filterPipe.transform(this.coinScans, this.filterFormGroup.value);
  }

  requestData() {
    this.isWait = true;
    var qparams: any = {};
    qparams.categoryCode = this.queryCategoryCode;
    qparams.coinDate = this.queryCoinDate;
    var coinMintPrm = '';
    for (let i = 0; i < this.filterFormGroup.get('coinMintArr').value.length; i++) {
      if (coinMintPrm != '') {
        coinMintPrm += ',';
      }
      coinMintPrm += this.filterFormGroup.get('coinMintArr').value[i];
    }
    qparams.coinMint = coinMintPrm;
    qparams.queryCoinFeature = this.queryCoinFeature;
    qparams.sortingType = this.sortingType;
    qparams.viewType = this.viewType;
    qparams.valueIndicatorList = 'HIGH,MED,LOW';
    if (this.listEndDate != '') {
      qparams.listEndDate = this.listEndDate;
    }
    if (this.listStartDate != '') {
      qparams.listStartDate = this.listStartDate;
    }
    //console.log('qparams', qparams);
    this._coinSearchService.getCoinScans(((this.coinsScansPage - 1) * this.coinsPerPage).toString(), this.coinsPerPage.toString(), qparams)
      .subscribe({
        next: (data) => this.renderStatus(data),
        error: (error) => console.error('Error!', error)
      });

    this._coinSearchService.getCategoryList()
      .subscribe({
        next: (data) => this.renderCategories(data),
        error: (error) => console.error('Error!', error)
      });

    this._coinSearchService.getCoinFeaturesQueryList()
      .subscribe({
        next: (data) => this.coinFeatures = data,
        error: (error) => console.error('Error!', error)
      });
  }

  loadPage(pageNum) {
    this.coinsScansPage = pageNum;
    this.requestData();
  }

  getCoinLink(coinId) {
    return this.route.createUrlTree(['/coinscanview', coinId]);
  }

  changeViewType(type: 'NORMAL' | 'BRIEF' | 'VALUE'): void {
    this.viewType = type;
    this.coinsScansPage = 1;
    if (this.viewType == 'BRIEF') {
      this.coinsPerPage = this.coinsPerPageBrief;
    } else {
      this.coinsPerPage = this.coinsPerPageNormal;
    }

    this.requestData();
  }

  deleteCoin(customId: string) {
    if (confirm('Are you sure you want to delete this coin?')) {
      this._coinSearchService.deleteScans(customId).subscribe(
        () => {
          // Remove the deleted coin from the filteredCoins array
          this.filteredCoins = this.filteredCoins.filter(coin => coin['customid'] !== customId);
          // Optionally, you can also update the coinScansCount
          this.coinScansCount--;
          // Show a success message
          //alert('Coin deleted successfully');
        },
        error => {
          console.error('Error deleting coin:', error);
          alert('Failed to delete coin. Please try again.');
        }
      );
    }
  }
}
